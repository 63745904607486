/*
 *  This file team-dashboards.model.ts is part of HROne Inbox. The intelectual property owned by Uneecops Workplace Solution PVT. LTD.
 *  CopyrightYear: 2023
 *  (C) 2015-2023. All Right reserved. Uneecops Workplace Solution PVT. LTD.
 *
 */

import { FormControl } from "@angular/forms";
import { TAutoCompleteSelectionItem } from "../../analytics/reports/generate-reports/generate-reports.model";

import { ReplaySubject } from 'rxjs';

export class SharedStore<T> extends ReplaySubject<T> {
  private _currentValue: T|undefined;

  next(value: T): void {
    this._currentValue = value;
    super.next(value);
  }

  getValue(): T|undefined {
    if (this._currentValue === undefined) {
      return undefined;
    }
    return this._currentValue;
  }
}


















export type TSidebarMenu =

    {
        menuName: string;
        sequenceNumber: number;
        menuId: number;
        moduleId: number;
        parentKey: number;
        menuType: string;
        iconImagePath: string;
        navigationPath: string;
        activeStatus: string;
        tooltipText: string;
        controlKey: string;
        cssClassName: string;
        includeMenu: boolean;
        navigationCode: string;
        isPin: boolean;
        sub_menu: TSidebarMenu[];
        accessRightId?:number;
    }


export type TWorkForceEmployeeTeam = {
    employeeName: string,
    employeeCode: string,
    employeeId: number,
    moduleId: number,
}




export type TSubscribeModuleResponse = {
    domainCode:      string;
    subscribeModule: TSubscribeModule[];
}

export type TSubscribeModule = {
    moduleId:   number;
    moduleName: string;
    isReportEnabled : boolean;
}

export type TAllProcessSidebar = TSubscribeModule & {submenu:Array<{processDomainId:number,processName:string,checked:boolean,isFavourite:boolean}>,selected:boolean}




export type TFGOrganisationUnit =  {
    companyCode:FormControl<Array<{key:number,value:string}>>,
    businessUnitCode:FormControl<Array<{key:number,value:string}>>,
    departmentCode:FormControl<Array<{key:number,value:string}>>,
    subDepartmentCode:FormControl<Array<{key:number,value:string}>>,
    designationCode:FormControl<Array<{key:number,value:string}>>,
    gradeCode:FormControl<Array<{key:number,value:string}>>,
    levelCode:FormControl<Array<{key:number,value:string}>>,
    regionCode:FormControl<Array<{key:number,value:string}>>,
    branchCode:FormControl<Array<{key:number,value:string}>>,
    subBranchCode:FormControl<Array<{key:number,value:string}>>,
    employeeType:FormControl<Array<{key:number,value:string}>>,
    employeeStatus:FormControl<Array<{key:number,value:string}>>,
    reportingManagerEmployeeId:FormControl<TAutoCompleteSelectionItem[]>,
    functionalManagerEmployeeId:FormControl<TAutoCompleteSelectionItem[]>,
    employeeCode:FormControl<TAutoCompleteSelectionItem[]>,

}

export type TTeamDashboardOuFilerFormControlKeys ="companyCode"| "businessUnitCode"| "departmentCode"| "subDepartmentCode"| "designationCode"| "gradeCode"| "levelCode"| "regionCode"| "branchCode"| "subBranchCode"| "employeeType"| "employeeStatus"| "reportingManagerEmployeeId"| "functionalManagerEmployeeId"| "employeeCode";
