// @ts-nocheck
/* 
 *  This file location-dropdowns.service.ts is part of HROne Inbox. The intelectual property owned by Uneecops Workplace Solution PVT. LTD.
 *  CopyrightYear: 2023  
 *  (C) 2015-2023. All Right reserved. Uneecops Workplace Solution PVT. LTD.
 *  
 */

import { Injectable } from '@angular/core';
import { HttpService } from '../http-service';
import { sortBy } from 'lodash';
import { countryUrls } from '../../../modules/core/organization/location/country/county.model';
import { stateUrls, stateGridRequestData } from '../../../modules/core/organization/location/state/state.model';
import { cityUrls, cityGridRequestData } from '../../../modules/core/organization/location/city/city.model';
import { regionUrls } from '../../../modules/core/organization/location/region/region.model';
import { branchUrls } from '../../../modules/core/organization/location/branch/branch.model';
import { subBranchUrls, subBranchGridRequestData } from '../../../modules/core/organization/location/sub-branch/sub-branch.model';
import { map } from 'rxjs/operators';
import { of  } from 'rxjs';
import { TKeyValue } from '../../models/common.models';

enum locatonDdUrls {
  branchMapped = '/api/core/Branch/Mapped/',
  stateCountryWise = '/api/core/State/BindDropDown',
  cityStateWise = '/api/core/City/BindDropDown',
  subBranchBranchWise = '/api/core/SubBranch/BindDropDown',
  branchStateWise = '/api/core/Branch/BindDropDown'
}

@Injectable({
  providedIn: 'root'
})
export class LocationDropdownsService {

  countryDd: any[] = [];
  stateDd: any[] = [];
  cityDd: any[] = [];
  stateDdCountryWise: any[] = [];
  cityDdStateWise: any[] = [];
  regionDd: any[] = [];
  branchDd: any[] = [];
  subBranchDd: any[] = [];
  subBranchDdBranchWise: any[] = [];

  countryActiveDd: Array<TKeyValue<string,string> & {isdCode:string}> = [];
  stateActiveDd: any[] = [];
  cityActiveDd: any[] = [];
  stateActiveDdCountryWise: any[] = [];
  cityActiveDdStateWise: any[] = [];
  regionActiveDd: any[] = [];
  branchActiveDd: any[] = [];
  subBranchActiveDd: any[] = [];
  subBranchActiveDdBranchWise: any[] = [];

  branchMappedActiveDd: any[] = [];
  branchActiveDdStateWise: any[] = [];
  branchDdStateWiseForChallan: any[] = []
  branchActiveDdWithState: any[] = []

  constructor(
    private _httpService: HttpService,
  ) { }

  resetCountryDropDown() {
    this.countryDd = [];
    this.getCountryDropDown();
  }

  getCountryDropDown(bgvVerificationDetails: any[] = []) {
    return new Promise((resolve, reject) => {
      if (this.countryDd.length) {
        return;
      }
      this._httpService.Get(countryUrls.dropdown).subscribe((response:   TCoreCountryResponseItem[]) => {

        const list = sortBy(response, [e => e.countryName.toLowerCase()]);
        this.countryDd = list.map(mapper => ({ value: mapper.countryName, key: mapper.countryCode, isdCode: mapper.internationalCode }));

        const filteredList = list.filter(data => data.activeStatus === '1');
        this.countryActiveDd = filteredList.map(mapper => ({ value: mapper.countryName, key: mapper.countryCode, isdCode: mapper.internationalCode }));
        bgvVerificationDetails.forEach(bgvDetail => {
          if ((bgvDetail.originalFieldName === 'FK_COUNTRYCODE_CUR' || bgvDetail.originalFieldName === 'FK_COUNTRYCODE_PER') && bgvDetail.aadhaarKycFieldValue) {
            const country = this.countryActiveDd.find(country => (country.value.toLowerCase() == bgvDetail.aadhaarKycFieldValue.toLowerCase()) || (country.value.toLowerCase() == bgvDetail.aadharValue.toLowerCase()));
            bgvDetail.aadhaarKycFieldValue = country ? country.key.toString() : bgvDetail.aadhaarKycFieldValue;
          }
          if ((bgvDetail.originalFieldName === 'FK_COUNTRYCODE_CUR' || bgvDetail.originalFieldName === 'FK_COUNTRYCODE_PER') && bgvDetail.panKycFieldValue) {
            const country = this.countryActiveDd.find(country => (country.value.toLowerCase() == bgvDetail.panKycFieldValue.toLowerCase()) || (country.value.toLowerCase() == bgvDetail.panValue.toLowerCase()));
            bgvDetail.panKycFieldValue = country ? country.key.toString() : bgvDetail.panKycFieldValue;
          }
        })
        resolve(this.countryActiveDd)
      });
    });


  }

  resetStateDropDown() {
    this.stateDd = [];
    this.getStateDropDown();
  }

  getStateDropDown() {

    if (this.stateDd.length) {
      return;
    }

    const req = {
      "stateCode": "",
      "countryCode": "",
      "status": "1,0",
      "pagination": {
        "pageNumber": 0,
        "pageSize": 0
      }
    };

    this._httpService.Post(stateUrls.stateDropdown, req).subscribe((response) => {
      const list = sortBy(response, [e => e.stateName.toLowerCase()]);
      this.stateDd = list.map(mapper => ({ value: mapper.stateName, key: mapper.stateCode }));

      const filteredList = list.filter(data => data.activeStatus === '1');
      this.stateActiveDd = filteredList.map(mapper => ({ value: mapper.stateName, key: mapper.stateCode }));

    });
  }

  getStateDropDownCountryWise(countryCode, bgvVerificationDetails: any[] = []) {
    this.stateDdCountryWise = [];
    this.stateActiveDdCountryWise = [];
    const stateGridRequest = stateGridRequestData;
    stateGridRequest.countryCode = countryCode;
    stateGridRequest.pagination.pageNumber = 0;
    stateGridRequest.pagination.pageSize = 9999;
    stateGridRequest.status = '0,1';

    this._httpService.Post(locatonDdUrls.stateCountryWise, stateGridRequest).subscribe(
      (response) => {

        const list = sortBy(response, [e => e.stateName.toLowerCase()]);
        this.stateDdCountryWise = list.map(mapper => ({ value: mapper.stateName, key: mapper.stateCode }));

        const filteredList = list.filter(data => data.activeStatus === '1');
        this.stateActiveDdCountryWise = filteredList.map(mapper => ({ value: mapper.stateName, key: mapper.stateCode }));
        bgvVerificationDetails.forEach(bgvDetail => {
          if (bgvDetail.originalFieldName === 'FK_STATECODE_CUR' && bgvDetail.aadhaarKycFieldValue) {
            const state = this.stateActiveDdCountryWise.find(state => (state.value.toLowerCase() == bgvDetail.aadhaarKycFieldValue.toLowerCase()) || (state.value.toLowerCase() == bgvDetail.aadharValue.toLowerCase()));
            bgvDetail.aadhaarKycFieldValue = state ? state.key.toString() : bgvDetail.aadhaarKycFieldValue;
          }
          if (bgvDetail.originalFieldName === 'FK_STATECODE_CUR' && bgvDetail.panKycFieldValue) {
            const state = this.stateActiveDdCountryWise.find(state => (state.value.toLowerCase() == bgvDetail.panKycFieldValue.toLowerCase()) || (state.value.toLowerCase() == bgvDetail.panValue.toLowerCase()));
            bgvDetail.panKycFieldValue = state ? state.key.toString() : bgvDetail.panKycFieldValue;
          }
        })
      });
  }

  resetCityDropDown() {
    this.cityDd = [];
    this.getCityDropDown();
  }

  getCityDropDown(isOtherRequired = false) {

    if (this.cityDd.length) {
      const index = this.cityActiveDd.findIndex(city => city.key == 0);
      if (isOtherRequired && index == -1)
        this.cityActiveDd.push({ value: 'Other', key: '0' });
      else if (!isOtherRequired && index > -1)
        this.cityActiveDd.splice(index, 1);
      return;
    }

    this._httpService.Get(cityUrls.cityDropdown).subscribe((response) => {

      const list = sortBy(response, [e => e.cityName.toLowerCase()]);
      this.cityDd = list.map(mapper => ({ value: mapper.cityName, key: mapper.cityCode }));

      const filteredList = list.filter(data => data.activeStatus === '1');
      this.cityActiveDd = filteredList.map(mapper => ({ value: mapper.cityName, key: mapper.cityCode }));
      if (isOtherRequired)
        this.cityActiveDd.push({ value: 'Other', key: '0' });
    });
  }

  getCityDropDownStateWise(stateCode, bgvVerificationDetails: any[] = []) {
    this.cityDdStateWise = [];
    this.cityActiveDdStateWise = [];
    const cityGridRequest = cityGridRequestData;
    cityGridRequest.stateCode = stateCode;
    cityGridRequest.pagination.pageNumber = 0;
    cityGridRequest.pagination.pageSize = 9999;
    cityGridRequest.cityStatus = '0,1';
    if (stateCode) {
      this._httpService.Post(locatonDdUrls.cityStateWise, cityGridRequest).subscribe(
        (response) => {

          const list = sortBy(response, [e => e.cityName.toLowerCase()]);
          this.cityDdStateWise = list.map(mapper => ({ value: mapper.cityName, key: mapper.cityCode }));

          const filteredList = list.filter(data => data.activeStatus === '1');
          this.cityActiveDdStateWise = filteredList.map(mapper => ({ value: mapper.cityName, key: mapper.cityCode }));
          bgvVerificationDetails.forEach(bgvDetail => {
            if (bgvDetail.originalFieldName === 'FK_CITY_CODE_CUR' && bgvDetail.aadhaarKycFieldValue) {
              const city = this.cityActiveDdStateWise.find(city => (city.value.toLowerCase() == bgvDetail.aadhaarKycFieldValue.toLowerCase()) || (city.value.toLowerCase() == bgvDetail.aadharValue.toLowerCase()));
              bgvDetail.aadhaarKycFieldValue = city ? city.key.toString() : bgvDetail.aadhaarKycFieldValue;
            }
            if (bgvDetail.originalFieldName === 'FK_CITY_CODE_CUR' && bgvDetail.panKycFieldValue) {
              const city = this.cityActiveDdStateWise.find(city => (city.value.toLowerCase() == bgvDetail.panKycFieldValue.toLowerCase()) || (city.value.toLowerCase() == bgvDetail.panValue.toLowerCase()));
              bgvDetail.panKycFieldValue = city ? city.key.toString() : bgvDetail.panKycFieldValue;
            }
          })
        });
    }
  }

  resetRegionDropDown() {
    this.regionDd = [];
    this.getRegionDropDown();
  }

  getRegionDropDown() {
    return new Promise((resolve, reject) => {

      if (this.regionDd.length) {
        resolve(null);
        return;
      }

      this._httpService.Get(regionUrls.dropdown).subscribe((response) => {

        const list = sortBy(response, [e => e.regionName.toLowerCase()]);
        this.regionDd = list.map(mapper => ({ value: mapper.regionName, key: mapper.regionCode }));
        const filteredList = list.filter(data => data.activeStatus === '1');
        this.regionActiveDd = filteredList.map(mapper => ({ value: mapper.regionName, key: mapper.regionCode }));
        resolve(null);
      });

    });
  }

  resetBranchDropDown() {
    this.branchDd = [];
    this.getBranchDropDown();
  }

  getBranchDropDown(): Promise<any> {
    return new Promise((resolve, reject) => {

      if (this.branchDd.length) {
        resolve(null);
        return;
      }

      this._httpService.Get(branchUrls.branchDropdown).subscribe((response) => {

        const list = sortBy(response, [e => e.branchName.toLowerCase()]);
        this.branchDd = list.map(mapper => ({ value: mapper.branchName, key: mapper.branchCode }));

        const filteredList = list.filter(data => data.activeStatus === '1');
        this.branchActiveDd = filteredList.map(mapper => ({ value: mapper.branchName, key: mapper.branchCode }));
        this.branchActiveDdWithState = filteredList.map(mapper => ({ value: mapper.branchName, key: mapper.branchCode, state: mapper.state }));
        resolve(null);
      });
    });
  }

  resetSubBranchDropDown() {
    this.subBranchDd = [];
    this.getSubBranchDropDown();
  }

  getSubBranchDropDown() {
    return new Promise((resolve, reject) => {

      if (this.subBranchDd.length) {
        resolve(null);
        return;
      }

      this._httpService.Get(subBranchUrls.subBranchDropdown).subscribe((response) => {

        const list = sortBy(response, [e => e.subBranchName.toLowerCase()]);
        this.subBranchDd = list.map(mapper => ({ value: mapper.subBranchName, key: mapper.subBranchCode }));

        const filteredList = list.filter(data => data.activeStatus === '1');
        this.subBranchActiveDd = filteredList.map(mapper => ({ value: mapper.subBranchName, key: mapper.subBranchCode }));

        resolve(null);
      });

    });
  }

  getSubBranchDropDownBranchWise(branchCode) {
    this.subBranchDdBranchWise = [];
    this.subBranchActiveDdBranchWise = [];
    const subBrancGridRequest = subBranchGridRequestData;
    subBrancGridRequest.branchCodeFilter = branchCode;
    subBrancGridRequest.pagination.pageNumber = 0;
    subBrancGridRequest.pagination.pageSize = 0;
    subBrancGridRequest.subBranchStatusFilter = '0,1';

    this._httpService.Post(locatonDdUrls.subBranchBranchWise, subBrancGridRequest).subscribe((res) => {
      const response = res.subBranchModel ? res.subBranchModel : [];

      const list = sortBy(response, [e => e.subBranchName.toLowerCase()]);
      this.subBranchDdBranchWise = list.map(mapper => ({ value: mapper.subBranchName, key: mapper.subBranchCode }));

      const filteredList = list.filter(data => data.activeStatus === '1');
      this.subBranchActiveDdBranchWise = filteredList.map(mapper => ({ value: mapper.subBranchName, key: mapper.subBranchCode }));

    });
  }

  getBranchMappedDropDown(buCode, regionCode) {
    this.branchMappedActiveDd = [];
    this._httpService.Get(locatonDdUrls.branchMapped + buCode + '/' + regionCode).subscribe((response) => {

      const list = sortBy(response, [e => e.branchName.toLowerCase()]);

      const filteredList = list.filter(data => data.activeStatus === '1');
      this.branchMappedActiveDd = filteredList.map(mapper => ({ value: mapper.branchName, key: mapper.branchCode }));
    });
  }

  getBranchOnStateChangedDropDown(stateCode?: string,isZeroIncluded? : boolean) {
    if (!stateCode) {
      return of(null);
    }
    this.branchActiveDdStateWise = [];
    const branchGridRequest: any = {
      branchCodeFilter: '',
      branchStatusFilter: isZeroIncluded ? '1,0' : '1',
      branchRegionFilter: '',
      branchStateFilter: stateCode,
      pagination: {
        pageNumber: 0,
        pageSize: 0
      }
    };
    return this._httpService.Post(locatonDdUrls.branchStateWise, branchGridRequest)
      .pipe(map(
        (response) => {
          const list = sortBy(response, [e => e.branchName.toLowerCase()]);
          const filteredList = list.filter(data => data.activeStatus === '1');
          this.branchActiveDdStateWise = isZeroIncluded ? response.map(mapper => ({value: mapper.branchName, key: mapper.branchCode}))  : filteredList.map(mapper => ({ value: mapper.branchName, key: mapper.branchCode }));
          return this.branchActiveDdStateWise;
        }));
  }

  reset() {
    this.countryDd = [];
    this.stateDd = [];
    this.cityDd = [];
    this.stateDdCountryWise = [];
    this.cityDdStateWise = [];
    this.regionDd = [];
    this.branchDd = [];
    this.subBranchDd = [];
    this.subBranchDdBranchWise = [];
    this.countryActiveDd = [];
    this.stateActiveDd = [];
    this.cityActiveDd = [];
    this.stateActiveDdCountryWise = [];
    this.cityActiveDdStateWise = [];
    this.regionActiveDd = [];
    this.branchActiveDd = [];
    this.subBranchActiveDd = [];
    this.subBranchActiveDdBranchWise = [];
    this.branchMappedActiveDd = [];
    this.branchActiveDdStateWise = [];
    this.branchDdStateWiseForChallan = [];
  }

  allDropDown() {
    this.getRegionDropDown();
    this.getBranchDropDown();
    this.getSubBranchDropDown();
  }

}

export type TCoreCountryResponseItem = {
  logOnUserCode:        string;
  domainCode:           string;
  logOnUserId:          number;
  logOnUserName:        string;
  currentUserLogOnType: string;
  countryCode:          string;
  countryName:          string;
  internationalCode:    string;
  nationality:          string;
  activeStatus:         string;
}