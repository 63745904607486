// @ts-nocheck
/* 
 *  This file validations.enum.ts is part of HROne Inbox. The intelectual property owned by Uneecops Workplace Solution PVT. LTD.
 *  CopyrightYear: 2022  
 *  (C) 2015-2022. All Right reserved. Uneecops Workplace Solution PVT. LTD.
 *  
 */

export const customValidations = {
    allowedCharacters: "^[ A-Za-z0-9@#$%&()*'.,?/\n:;!|_-]+$",
    allowedCharactersWithApostrophe: "^[ A-Za-z0-9@#$%&()*'.,?/\n:;!|_-]+$",
    AlphabetOnly: '[a-zA-Z ]+',
    AlphaNumericOnlyWithSpace: '^[0-9a-zA-Z ]+$',
    AlphaNumericOnly: '^[0-9a-zA-Z]+$',
    NumericOnly: '[0-9]*',
    HoursDecimal:'^\\d{0,11}(\\.\\d{1})?$',
    NumericDecimal: '^[-+]?[0-9]+\.[0-9]+$',
    NumericOrDecimal: '^\\d{0,11}(\\.\\d{1,2})?$', // 11,2
    NumericOrDecimal72: '^\\d{0,7}(\\.\\d{1,2})?$', // 7,2
    NumericOrDecimal32: '^\\d{0,3}(\\.\\d{1,2})?$', // 3,2,
    NumericOrDecimal42: '^\\d{0,4}(\\.\\d{1,2})?$', // 4,2,
    NumericOrDecimal132: '^\\d{0,13}(\\.\\d{1,2})?$', // 13,2,
    NumericOrDecimal22: '^\\d{0,2}(\\.\\d{1,2})?$', // 3,2,
    NumericOrDecimal92: '^\\d{0,9}(\\.\\d{1,2})?$', // 9,2
    NumericOrDecimal62: '^\\d{0,6}(\\.\\d{1,2})?$', // 6,2
    NumericOrDecimal82: '^\\d{0,8}(\\.\\d{1,2})?$', // 8,2
    NumericOrDecimalGeneric: '^\\d{0,11}(\\.\\d{1,20})?$',
    NumericOrDecimalNegative: '^-?\\d{0,9}(\\.\\d{1,2})?$',
    NumericOrDecimal152: '^\\d{0,15}(\\.\\d{1,2})?$', // 15,2
    Percentage: '^\\d*[0-9](|.[0-9]{1,2})?$',
    NumericOnlyWithoutZero: '^[1-9][0-9]*?',
    NumericOrDecimal25: '^\\d{0,2}(\\.\\d{1,5})?$', // 2,5
    // tslint:disable-next-line:max-line-length
    website: /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/,
    notZero: '[^0]+',
    email: '^([a-zA-Z0-9_\\-\\.]+)@([a-zA-Z0-9_\\-\\.]+)\\.([a-zA-Z]{2,20})$',
    pan: '^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$',
    aadhar: '^[0-9]{12}$',
    passport: '^[a-zA-Z0-9]{8}$',
    mobile: '^[1-9][0-9]{9}$',
    passregex: '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$\^&\*]).{6,}$', //remove max value 35840
    onlyYoutube: '^(https?\:\/\/)?((www\.)?youtube\.com|youtu\.?be)\/.+$',
    afterDecimal0or5: '^\\d{0,9}(\\.(0|5))?$',  // can take only 0 or 5 after decimal
    uTubeOrVimeoRegex: /^(http:\/\/|https:\/\/)(vimeo\.com|youtu\.be|www\.youtube\.com)\/([\w\/]+)([\?].*)?$/,
    latitude: '^-?\\d{1,2}(\\.\\d{1,14})?$',
    longitude: '^-?\\d{1,3}(\\.\\d{1,14})?$',
    PaytmWallet: '^[0-9a-zA-Z-]+$',
    IPAddress: '^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$',
    /* IPAddress: "^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$" */
    url: /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/,
    onlyFacebook: /^(http:\/\/|https:\/\/)(www\.fb\.com|www\.facebook\.com)\/.+$/,
    onlyTwitter: /^(http:\/\/|https:\/\/)(twitter\.com|x\.com)\/.+$/,
    onlyLinkedin: /^(http:\/\/|https:\/\/)(www\.linkedin\.com)\/.+$/,
    onlyInstagram: /^(https?\:\/\/)?(www\.instagram\.com)\/.+$/,
    onlyBehance: /^(http:\/\/|https:\/\/)(www\.behance\.net)\/.+$/,
    onlyCodeforce: /^(http:\/\/|https:\/\/)(codeforces\.com)\/.+$/,
    onlyDribbble: /^(http:\/\/|https:\/\/)(dribbble\.com)\/.+$/,
    onlyGithub: /^(http:\/\/|https:\/\/)(github\.com)\/.+$/,
    onlyQuora: /^(http:\/\/|https:\/\/)(www\.quora\.com)\/.+$/,
    onlyMedium: /^(http:\/\/|https:\/\/)(medium\.com)\/.+$/,
    decimalNonZero : /^[1-9]\d*\.\d{5}$/,              /* upto 5 decimal places */
    decimalNonZero2 : /^(?:\d+|\d*\.\d+)$/              /* upto 2 decimal places */
};

export const socialHandlersValidation = {
    Facebook: customValidations.onlyFacebook,
    Twitter: customValidations.onlyTwitter,
    LinkedIn: customValidations.onlyLinkedin,
    Instagram: customValidations.onlyInstagram,
    Behance: customValidations.onlyBehance,
    Codeforce: customValidations.onlyCodeforce,
    Dribbble: customValidations.onlyDribbble,
    Github: customValidations.onlyGithub,
    Quora: customValidations.onlyQuora,
    Medium: customValidations.onlyMedium,
}

