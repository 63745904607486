
/* 
 *  This file region.model.ts is part of HROne Inbox. The intelectual property owned by Uneecops Workplace Solution PVT. LTD.
 *  CopyrightYear: 2022  
 *  (C) 2015-2022. All Right reserved. Uneecops Workplace Solution PVT. LTD.
 *  
 */

import { PaginationModel, LinksModel } from '../../../../../global/models/common.models';

export enum regionUrls {
    regionCount = '/api/core/Region/TotalCount',
    branchCount = '/api/core/Region/BranchCount/',
    employeeCount = '/api/core/Region/EmployeeCount/',
    search = '/api/core/Region/Search',
    dropdown = '/api/core/Region',
    insert = '/api/core/Region',
    update = '/api/core/Region',
    delete = '/api/core/Region'
}

export interface RegionGridRequestModel {
    regionCodeFilter: string;
    regionStatusFilter: string;
    pagination: PaginationModel;
}

export const regionGridRequestData: RegionGridRequestModel = {
    regionCodeFilter: '',
    regionStatusFilter: '1',
    pagination: {
        pageNumber: 1,
        pageSize: 15
    }
};

export interface RegionGridResponseModel {
    regionCode: string;
    regionName: string;
    activeStatus: string;
    links: LinksModel;
    // added
    employeeCount: string;
    branchCount: string;
}


export const filterSkeleton = {
    selected_region: [],
    active: true,
    inactive: false
};

