
/* 
 *  This file branch.model.ts is part of HROne Inbox. The intelectual property owned by Uneecops Workplace Solution PVT. LTD.
 *  CopyrightYear: 2022  
 *  (C) 2015-2022. All Right reserved. Uneecops Workplace Solution PVT. LTD.
 *  
 */

import { PaginationModel, LinksModel } from '../../../../../global/models/common.models';

export enum branchUrls {
    branchCount = '/api/core/Branch/TotalCount',
    employeeCount = '/api/core/Branch/EmployeeCount/',
    subBranchCount = '/api/core/Branch/SubBranchCount/',

    regionDropdown = '/api/core/Region',
    branchDropdown = '/api/core/Branch',
    stateDropdown = '/api/core/State',
    countryDropdown = '/api/core/Country',

    search = '/api/core/Branch/Search',
    insert = '/api/core/Branch',
    update = '/api/core/Branch',
    delete = '/api/core/Branch'
}

export interface BranchGridRequestModel {
    branchCodeFilter: string ;
    branchStatusFilter: string ;
    branchRegionFilter: string ;
    branchStateFilter: string ;
    pagination: PaginationModel;
}

export const branchGridRequestData: BranchGridRequestModel = {
    branchCodeFilter: '' ,
    branchStatusFilter: '1' ,
    branchRegionFilter: '' ,
    branchStateFilter: '' ,
    pagination: {
        pageNumber: 1,
        pageSize: 15
    }
};

export interface BranchGridResponseModel {
    branchCode: string;
    branchName: string;
    branchAddress: string;
    regionCode: string;
    regionName: string;
    stateCode: string;
    countryCode: string;
    activeStatus: string;
    pin: string;
    links: LinksModel;
    // added
    employeeCount: string;
    subBranchCount: string;
}


export const filterSkeleton = {
    selected_branch: [],
    selected_state: [],
    selected_region: [],
    active: true,
    inactive: false
};
