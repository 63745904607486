// @ts-nocheck
/*
 *  This file hrone-global-mobile-disclaimer.component.ts is part of HROne Inbox. The intelectual property owned by Uneecops Workplace Solution PVT. LTD.
 *  CopyrightYear: 2023
 *  (C) 2015-2023. All Right reserved. Uneecops Workplace Solution PVT. LTD.
 *
 */

import { Component, EventEmitter, Output } from '@angular/core';
import { NgIf } from '@angular/common';
import { BreakpointObserver, BreakpointState, Breakpoints } from '@angular/cdk/layout';
import { delay, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { SharedService } from '../../services/shared.service';
@Component({
  selector: 'app-hrone-global-mobile-disclaimer',
  standalone: true,
  imports: [NgIf],
  templateUrl: './hrone-global-mobile-disclaimer.component.html',
  styleUrls: ['./hrone-global-mobile-disclaimer.component.scss']
})
export class HroneGlobalMobileDisclaimerComponent {
  untilDestroyed$ = new Subject();
  displayNameMap = new Map([
    [Breakpoints.XSmall, 'XSmall'],
    ['(min-width: 600px) and (max-width: 839.98px)', 'Small'],
    ['(min-width: 840px)', 'Medium'],
  ]);
  isMobile = false;
  @Output() afterclose: EventEmitter<boolean> = new EventEmitter();
  @Output() isMobileViewNew: EventEmitter<boolean> = new EventEmitter();
  constructor(
    private breakpointObserver: BreakpointObserver,
    public sharedService: SharedService
  ) {

    this.breakpointObserver
      .observe([
        Breakpoints.XSmall,
        '(min-width: 600px) and (max-width: 839.98px)',
        '(min-width: 840px)',
      ]).pipe(delay(6000), takeUntil(this.untilDestroyed$))
      .subscribe((result: BreakpointState) => {
        for (const query of Object.keys(result.breakpoints)) {
          if (result.breakpoints[query]) {
            const size = this.displayNameMap.get(query);
            this.isMobile = size === 'XSmall';
            if (this.isMobile) {
              this.isMobileViewNew.emit(true);        

            }
            else {
              this.isMobileViewNew.emit(false);
            }

          }
        }
      });
  }



  closeMe(): void {
    this.afterclose.emit(true);
    this.untilDestroyed$.unsubscribe();
  }

}