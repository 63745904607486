/* 
 *  This file client-error-handler.service.ts is part of HROne Inbox. The intelectual property owned by Uneecops Workplace Solution PVT. LTD.
 *  CopyrightYear: 2022  
 *  (C) 2015-2022. All Right reserved. Uneecops Workplace Solution PVT. LTD.
 *  
 */

import { ErrorHandler, Injectable} from '@angular/core';
import { LogErrorsService } from './log-errors';

@Injectable()
export class ClientErrorHandler implements ErrorHandler {

  constructor(
    private _logError: LogErrorsService
  ) {

  }

  handleError(error: Error) {
     this._logError.logError(error);
  }

}
