// @ts-nocheck
/* 
 *  This file validators.ts is part of HROne Inbox. The intelectual property owned by Uneecops Workplace Solution PVT. LTD.
 *  CopyrightYear: 2022  
 *  (C) 2015-2022. All Right reserved. Uneecops Workplace Solution PVT. LTD.
 *  
 */

import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import * as moment from 'moment';
import { customValidations } from './validations.enum';

export function blankSpacesNotAllowed(control: AbstractControl) {
    const value: string = control.value;
    if (control.value !== null) {
        if (control.value !== undefined) {
            if (value.length > 0) {
                if (value.trim() === '') {
                    return { blankSpaceError: true };
                }
            }
        }
    }
    return null;
}

export function spacesNotAllowed(control: AbstractControl) {
    const value: string = control.value;
    if (value) {
        if (value.length > 0) {
            if (value.trim().split(' ').length > 1) {
                return { spaceError: true };
            }
        }
    }
    return null;
}

export function zeroOnlyNotAllowed(control: AbstractControl) {
    const value = +control.value;
    if (value === 0) {
        return { zeroOnlyError: true };
    }
    return null;
}

export function codeSeries20Allowed(control: AbstractControl) {
    const value: string = control.value;
    if (control.value !== null) {
        if (control.value !== undefined) {
            if (value.length > 20) {
                return { codeSeries20Error: true };
            }
        }
    }
    return null;
}

export function mandatoryField(control: AbstractControl) {
    let cv: string = control.value;
    if (cv) {
        cv = String(cv);
        cv = cv.trim();
        if (!cv) {
            return { mandatory: true };
        } else {
            return null;
        }
    }
    return { mandatory: true };
}

export function autoCompleteObjectSelection(input: AbstractControl) {
    if (input.value && typeof input.value !== 'object') {
        return { invalidSelection: true };
    } else {
        return null;
    }
}

export function validateDate(control: AbstractControl) {
    const cd: string = control.value;
    if (cd) {
        const date = moment(cd);
        if (!date.isValid()) {
            return { invalidDate: true };
        }
    }
    return null;
}

export function dateLessThanFrom(fromDate: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
        if (control && control.parent) {
            try {
                const fDate = control.parent.get(fromDate).value;
                const toDate = control.value;
                if (fDate && toDate && moment(toDate).isBefore(fDate)) {
                    return { dateLessThanError: true };
                }
            } catch (err) {

            }
        }
        return null;
    };
}

export function validateTime(maxHour?: number): ValidatorFn {  // if 24 hour format then pass it else ignore for unlimited time
    return (control: AbstractControl): { [key: string]: boolean } | null => {
        const value: string = control.value;
        if (value) {
            if (value.length === 5) {
                const left = Number(value[0] + value[1]);
                const right = Number(value[3] + value[4]);
                const center = value[2];
                if (center != ':') {
                    return { invalidTime: true };
                } else if (isNaN(left) || isNaN(right)) {
                    return { invalidTime: true };
                } else if (right >= 60) {
                    return { invalidTime: true };
                }

                if (maxHour) {
                    const max = Number(maxHour);
                    if (left > max || (left == max && right > 0)) {
                        return { timeExceed: true };
                    }
                }
            } else {
                return { invalidTime: true };
            }
        }
        return null;
    };
}

export function validatePAN(control: AbstractControl) {
    const cd: string = control.value;
    if (cd && cd.toLowerCase() != 'pannotavbl') {
        const panRegex: RegExp = new RegExp(customValidations.pan);
        if (!panRegex.test(cd)) {
            return { invalidPan: true };
        }
    }
    return null;
}


export function validateTimeWithCondition(maxHour?: number): ValidatorFn {  // if 24 hour format then pass it else ignore for unlimited time
    // maxhour = maxhours-1:59
    return (control: AbstractControl): { [key: string]: boolean } | null => {
        const value: string = control.value;
        if (value) {
            if (value.length === 5) {
                const left = Number(value[0] + value[1]);
                const right = Number(value[3] + value[4]);
                const center = value[2];
                if (center != ':') {
                    return { invalidTime: true };
                } else if (isNaN(left) || isNaN(right)) {
                    return { invalidTime: true };
                } else if (right >= 60) {
                    return { invalidTime: true };
                }

                if (maxHour) {
                    const max = Number(maxHour);
                    if (left > max || (left == max)) {
                        return { timeExceed: true };
                    }
                }
            } else {
                return { invalidTime: true };
            }
        }
        return null;
    };


}

export function oldCtcLessThanNew(OLD_CTC_DATE: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
        if (control && control.parent) {
            try {
                const fDate = control.parent.get(OLD_CTC_DATE).value;
                const toDate = control.value;
                if (fDate && toDate && moment(toDate).isBefore(fDate)) {
                    return { dateLessThanError: true };
                }
            } catch (err) {

            }
        }
        return null;
    };
}

export function emailListValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
        if (!control.value) {
            return null;
        }
        const emails = control.value.split(',').map((email: string) => email.trim());
        const invalidEmails = emails.filter((email: string) => !new RegExp(customValidations.email).test(email));
        if (invalidEmails.length > 0) {
            return { invalidEmails: true };
        }
        return null;
    };
}

export function decimalRangeValidator(min: number, max: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null;
      }
      const value = parseFloat(control.value);
      if (isNaN(value) || value < min || value > max) {
        return { decimalRange: true };
      }
      return null;
    }
}

export function rangeValidator(controlName1: string, controlName2: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        if (control.value && control.parent) {
            const max = Number(control.parent.get(controlName1).value);
            const min = Number(control.parent.get(controlName2).value);
            if (!min || !max) {
                return null;
            }
            if (max < min || max < 0 || min < 0) {
                return { 'minRange': true };
            } else {
                control.parent.get(controlName1).setErrors(null);
                control.parent.get(controlName2).setErrors(null);
            }
        } else return null;
    }
}

export function lowerThanValidator(otherControlName: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      const otherControl = control?.parent?.get(otherControlName);
      if (!otherControl || control.value === '') {
        return null;
      }
  
      if (Number(control.value) <= Number(otherControl.value)) {
        return { 'lowerThan': true };
      }
  
      return null;
    };
  }
  export function timeRangeValidator(controlName1: string, controlName2: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
    if (control.value && control.parent) {
      const startTime = control.parent.controls[controlName1].value;
      const endTime = control.parent.controls[controlName2].value;
      if (startTime && endTime) {
        const temp1 = convertTimeToDate(startTime).getTime();
        const temp2 = convertTimeToDate(endTime).getTime();
        if (temp2 < temp1 || temp1 === temp2) {
          return { incorrectTime: true };
        } else {
          const startTimeError = control.parent.controls[controlName1].errors;
          const endTimeError = control.parent.controls[controlName2].errors;
          if (startTimeError) {
            delete startTimeError['incorrectTime'];
            if (!Object.keys(startTimeError).length) {
              control.parent.controls[controlName1].setErrors(null);
              control.parent.controls[controlName1].markAsPristine();
            } else {
              control.parent.controls[controlName1].setErrors(startTimeError);
            }
          }
          if (endTimeError) {
            delete endTimeError['incorrectTime'];
            if (!Object.keys(endTimeError).length) {
              control.parent.controls[controlName2].setErrors(null);
              control.parent.controls[controlName2].markAsPristine();
            } else {
              control.parent.controls[controlName2].setErrors(endTimeError);
            }
          }
        }
      }
    }
  }
}

export function convertTimeToDate(time: string): Date {
    const date = new Date();
    if (typeof time == 'string') {
        return new Date(date.getFullYear(), date.getMonth(), date.getDate(), Number(time.substring(0, 2)), Number(time.substring(3)));
    }
    return time;
}