/*
 *  This file letter-template.constant.ts is part of HROne Inbox. The intelectual property owned by Uneecops Workplace Solution PVT. LTD.
 *  CopyrightYear: 2022
 *  (C) 2015-2022. All Right reserved. Uneecops Workplace Solution PVT. LTD.
 *
 */

export const letterTemplateIds = {
   relievingLetter: 4,
   terminationLetter: 5,
   appoinmentLetter: 1,
   miscellaneousLetter: 6,
   salary: 8,
   reimbursement: 9,
   tax: 10,
};


export const letterProcessTypeIds = {
   payslip: 5,
   salStruc: 6,
   settlement: 7
};
