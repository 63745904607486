
/* 
 *  This file recruitment.model.ts is part of HROne Inbox. The intelectual property owned by Uneecops Workplace Solution PVT. LTD.
 *  CopyrightYear: 2022  
 *  (C) 2015-2022. All Right reserved. Uneecops Workplace Solution PVT. LTD.
 *  
 */

export const displayNamesConstant = {
    JOB_ATTACHMENT: 'Job attachment',
    EXPERIENCE: 'Experience',
    FK_BRANCH_CODE: 'Branch',
    FK_DEPT_CODE: 'Department',
    FK_BUSINESSUNIT_CODE: 'Business unit',
    FK_COMPANY_CODE: 'Company',
    FK_DESIG_CODE: 'Designation',
    FK_EMP_TYPE_ID: 'Employment type',
    FK_FUNCTION_ID: 'Job function',
    FK_GRADE_CODE: 'Grade',
    FK_LEVEL_CODE: 'Level',
    FK_QUALIFICATION_CODE: 'Qualification',
    FK_REGION_CODE: 'Region',
    FK_REP_MGR_ID: 'Reporting manager',
    FK_SENIORITY_ID: 'Seniority',
    FK_SUBBRANCH_CODE: 'Sub branch',
    FK_SUBDEPT_CODE: 'Sub department',
    JOB_DESCRIPTION_BODY: 'Job description',
    JOB_TITLE: 'Job title',
    JOB_TYPE: 'Job type',
    NO_OF_OPENING: 'No of opening',
    REPLACED_EMPLOYEE_ID: 'Replaced employee',
    SALARY: 'Salary',
    SKILLS: 'Skills',
    URGENCY_STATUS: 'Urgency status'
};