
/* 
 *  This file request-menu.model.ts is part of HROne Inbox. The intelectual property owned by Uneecops Workplace Solution PVT. LTD.
 *  CopyrightYear: 2022  
 *  (C) 2015-2022. All Right reserved. Uneecops Workplace Solution PVT. LTD.
 *  
 */

export const RequestMenuModel = [
    {
        activeStatus: "1",
        controlKey: null,
        cssClassName: "Pending",
        iconImagePath: "Icon",
        includeMenu: true,
        menuId: 65,
        menuName: "Pending",
        menuType: "P",
        moduleId: 1,
        navigationCode: "pending_65",
        navigationPath: "/request/initiatedrequest",
        parentKey: 3,
        sequenceNumber: 1,
        status: true,
        sub_menu: [],
        tooltipText: "Pending"
    },
    {
        activeStatus: "1",
        controlKey: null,
        cssClassName: "Approved",
        iconImagePath: "Icon",
        includeMenu: true,
        menuId: 66,
        menuName: "Approved",
        menuType: "P",
        moduleId: 1,
        navigationCode: "approved_66",
        navigationPath: "/request/initiatedrequest",
        parentKey: 3,
        sequenceNumber: 2,
        status: true,
        sub_menu: [],
        tooltipText: "Approved"
    },
    {
        activeStatus: "1",
        controlKey: null,
        cssClassName: "Rejected",
        iconImagePath: "Icon",
        includeMenu: true,
        menuId: 67,
        menuName: "Rejected",
        menuType: "P",
        moduleId: 1,
        navigationCode: "rejected_67",
        navigationPath: "/request/initiatedrequest",
        parentKey: 3,
        sequenceNumber: 3,
        status: true,
        sub_menu: [],
        tooltipText: "Rejected"
    },
    {
        activeStatus: "1",
        controlKey: null,
        cssClassName: "Undo",
        iconImagePath: "Icon",
        includeMenu: true,
        menuId: 126,
        menuName: "Undo",
        menuType: "P",
        moduleId: 1,
        navigationCode: "undo_126",
        navigationPath: "/request/initiatedrequest",
        parentKey: 3,
        sequenceNumber: 4,
        status: true,
        sub_menu: [],
        tooltipText: "Undo"
    },
    {
        activeStatus: "1",
        controlKey: null,
        cssClassName: "Drafts",
        iconImagePath: "Icon",
        includeMenu: true,
        menuId: 68,
        menuName: "Drafts",
        menuType: "P",
        moduleId: 1,
        navigationCode: "drafts_68",
        navigationPath: "/request/initiatedrequest",
        parentKey: 3,
        sequenceNumber: 5,
        status: true,
        sub_menu: [],
        tooltipText: "Drafts"
    }
];
