/*
 *  This file global.effect.ts is part of HROne Inbox. The intelectual property owned by Uneecops Workplace Solution PVT. LTD.
 *  CopyrightYear: 2024
 *  (C) 2015-2024. All Right reserved. Uneecops Workplace Solution PVT. LTD.
 *
 */

import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';

import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import {
  loadBadgeFeedSuccess,
  loadGlboalStateFromStorage,
  updateGlboalStateFromStorage,
  emptyaction,
  refreshPageAccessRightSuccess,
  refreshPageAccessRightStart,
  contestEnableDisableSuccess,
  subscribeModuleSuccess,
  workforceSettingSuccess,
  validateFeatureSuccess
} from './global.action';
import { TGlobalState, TGlobalStateProcessVersion } from './global.reducer';
import { Store } from '@ngrx/store';
import { selectGlobalState } from './global.selector';
import { EGlobalStateProcessId, EGlobalStateStoreNames } from './global.constants';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { ServerErrorHandler } from 'src/app/global/services/error-handler/server-error-handler.service';
import { EMPTY, Observable, of } from 'rxjs';
import { HroneGlobalStateService } from './global-state.service';

@Injectable()
export class HroneGlobalStateEffects {

  constructor(
    private actions$: Actions,
    private store :Store<TGlobalState>,
    private _indexdb : NgxIndexedDBService,
    private _serverErrorHandler:ServerErrorHandler,
    private _globalState:HroneGlobalStateService,
  ) { }











  setGlboalStateFromStorage$ = createEffect(() =>
    { return this.actions$.pipe(
      ofType(loadGlboalStateFromStorage),
      concatLatestFrom(() => this.store.select<TGlobalState>(selectGlobalState)),
      switchMap(()=>{
         return this._indexdb.getAll<any>('hronestore').pipe(
          map((state)=>updateGlboalStateFromStorage({state}))
         )

      })
    ) }
  );
  setGlobalStateToStorage$ = createEffect(() =>
    { return this.actions$.pipe(
      ofType(loadBadgeFeedSuccess,
        refreshPageAccessRightSuccess,
        contestEnableDisableSuccess,
        subscribeModuleSuccess,
        workforceSettingSuccess,
        validateFeatureSuccess,
      ),
      concatLatestFrom(() => this.store.select<TGlobalState>(selectGlobalState)),
      mergeMap(([action, globalState]) => {
        const storename: keyof typeof EGlobalStateStoreNames = action.storename as keyof typeof EGlobalStateStoreNames;


          const storeData = (globalState as unknown as {hroneGlobal:TGlobalState}).hroneGlobal[storename as keyof TGlobalState];



          return this.addOrUpdate('hronestore',storeData.processId, storeData).pipe(
            map(() => emptyaction())
          );




      })
    ) }
  );

  pageaccessright$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(refreshPageAccessRightStart),
      switchMap(() =>
        this._indexdb.getByKey<TGlobalStateProcessVersion<{
          data: boolean,
          loading: boolean,
        }>>('hronestore', EGlobalStateProcessId.PageAccessRights).pipe(
          switchMap((dbData) => {
            if (dbData && dbData.data && this._globalState.shouldGetFromCache(dbData.versionId,EGlobalStateProcessId.PageAccessRights,dbData.employeeId,dbData.domainCode,dbData.date)) {
              return of(dbData.data).pipe(
                map(() => {
                  return emptyaction();
                })
              );
            } else {
              return this._serverErrorHandler.onRefreshLogon().pipe(
                map((response: boolean) => {
                  if (response) {
                    this._serverErrorHandler.refreshMenus();
                  }
                  return emptyaction();
                })
              );
            }
          })
        )
      ),
      tap(()=>{
        this._globalState.getVersionDetails().subscribe({next:(data)=>this._globalState.handleVersionDetails(data)})
      })
    );
  });


  addOrUpdate<T>(storeName: string, key: number, data: T): Observable<T> {
    return this._indexdb.getByKey<T>(storeName, key).pipe(
      switchMap((existingData) => {
          return existingData ? this._indexdb.update<T>(storeName, {...data,date:new Date()}): this._indexdb.add(storeName, {...data,date:new Date()});
      }),
      catchError(() => {
        throw EMPTY;
      })
    );
  }

}


