
/* 
 *  This file city.model.ts is part of HROne Inbox. The intelectual property owned by Uneecops Workplace Solution PVT. LTD.
 *  CopyrightYear: 2022  
 *  (C) 2015-2022. All Right reserved. Uneecops Workplace Solution PVT. LTD.
 *  
 */

import { PaginationModel, LinksModel } from '../../../../../global/models/common.models';

export enum cityUrls {
    cityCount = '/api/core/City/TotalCount',
    employeeCount = '/api/core/City/EmployeeCount/',
    search = '/api/core/City/Search',
    cityDropdown = '/api/core/City',
    stateDropdown = '/api/core/State',
    countryDropdown = '/api/core/Country',
    insert = '/api/core/City',
    update = '/api/core/City',
    delete = '/api/core/City'
}

export interface CityGridRequestModel {
    cityCode: string;
    stateCode: string;
    countryCode: string;
    cityStatus: string;
    pagination: PaginationModel;
}

export const cityGridRequestData: CityGridRequestModel = {
    cityCode: '',
    stateCode: '',
    countryCode: '',
    cityStatus: '1',
    pagination: {
        pageNumber: 1,
        pageSize: 15
    }
};

export interface CityGridResponseModel {
    cityCode: string ;
    cityName: string ;
    stateCode: string ;
    stateName: string ;
    countryCode: string ;
    countryName: string ;
    activeStatus: string ;
    isMetro: boolean;
    links: LinksModel;
    // added
    employeeCount: string;
}


export const filterSkeleton = {
    selected_country: [],
    selected_state: [],
    selected_city: [],
    active: true,
    inactive: false
};
