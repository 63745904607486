/*
 *  This file global.action.ts is part of HROne Inbox. The intelectual property owned by Uneecops Workplace Solution PVT. LTD.
 *  CopyrightYear: 2024
 *  (C) 2015-2024. All Right reserved. Uneecops Workplace Solution PVT. LTD.
 *
 */


import { createAction, props } from '@ngrx/store';
import { TGlobalState } from './global.reducer';
import { FeedPayloadState, IFeedResponseExtended } from 'src/app/modules/enterprise-wall/social-enterpise-wall/social-wall.model';
import { TContestEnableDisableResponse, TCoreFeatureValidate, TWorkforceSetupSettingResponse } from './global.constants';
import { TSubscribeModuleResponse } from 'src/app/modules/team/team-dashboards/team-dashboards.model';


export const refreshPageAccessRightStart = createAction(
  '[LogOnUser] Refresh Page Access Right Start',

);
export const emptyaction = createAction(
  '[Global State] EMPTY action',

);

export const refreshPageAccessRightSuccess = createAction(
  '[LogOnUser] Refresh Page Access Right Success',
  props<{ data: any, storename: string, versionId: string, employeeId: number, domainCode: string }>()
);

export const refreshPageAccessRightFailure = createAction(
  '[LogOnUser] Refresh Page Access Right Failure',

);



export const contestEnableDisableStart = createAction(
  '[Contest] Contest Enable Disable Start',
  props<{ isHtmlData: boolean }>()
);

export const contestEnableDisableSuccess = createAction(
  '[Contest] Contest Enable Disable Success',
  props<{ data: TContestEnableDisableResponse, storename: string, versionId: string, employeeId: number, domainCode: string }>()
);

export const contestEnableDisableFailure = createAction(
  '[Contest] Contest Enable Disable Failure',

);


export const loadBadgeFeedStart = createAction(
  '[Engage] Load Badge Feed Start',
  props<{ payload: FeedPayloadState }>(),
);

export const loadBadgeFeedSuccess = createAction(
  '[Engage] Load Badge Feed Success',
  props<{ data: IFeedResponseExtended[], storename: string, versionId: string, employeeId: number, domainCode: string }>()
);

export const loadBadgeFeedFailure = createAction(
  '[Engage] Load Badge Feed Failure',

);


export const subscribeModuleStart = createAction(
  '[SubscribeModule] Subscribe Module Start',

);

export const subscribeModuleSuccess = createAction(
  '[SubscribeModule] Subscribe Module Success',
  props<{ data: TSubscribeModuleResponse | null, storename: string, versionId: string, employeeId: number, domainCode: string }>()
);
export const validateFeatureSuccess = createAction(
  '[Validate] Feature Success',
  props<{ data: Map<number, TCoreFeatureValidate>, storename: string, versionId: string, employeeId: number, domainCode: string }>()
);

export const subscribeModuleFailure = createAction(
  '[SubscribeModule] Subscribe Module Failure',

);


export const workforceSettingStart = createAction(
  '[Workforce Setup] workforce Setting Start',
  props<{ settingId: string; activeStatus: number }>()
);

export const workforceSettingSuccess = createAction(
  '[Workforce Setup] workforce Setting Success',
  props<{
    data: Map<number, TWorkforceSetupSettingResponse>
    , storename: string, versionId: string, employeeId: number, domainCode: string
  }>()
);
export const workforceSettingFailure = createAction(
  '[Workforce Setup] workforce Setting Failed',

);
export const loadGlboalStateFromStorage = createAction(
  '[Global State] load state from storage',

);
export const updateGlboalStateFromStorage = createAction(
  '[Global State] update state from storage', props<{ state: any }>()

);
export const setGlboalStateToStorage = createAction(
  '[Global State] set state to storage', props<{ state: TGlobalState }>()

);


