/*
 *  This file page-loader.service.ts is part of HROne Inbox. The intelectual property owned by Uneecops Workplace Solution PVT. LTD.
 *  CopyrightYear: 2022
 *  (C) 2015-2022. All Right reserved. Uneecops Workplace Solution PVT. LTD.
 *
 */

import { Injectable } from '@angular/core';

@Injectable()
export class PageLoaderService {

  public isPageLoading = false;

  public show() {
    setTimeout(() => {
      this.isPageLoading = true;
    }, 0);
  }

  public hide() {
    setTimeout(() => {
      this.isPageLoading = false;
    }, 0);
  }


}


