
/* 
 *  This file county.model.ts is part of HROne Inbox. The intelectual property owned by Uneecops Workplace Solution PVT. LTD.
 *  CopyrightYear: 2022  
 *  (C) 2015-2022. All Right reserved. Uneecops Workplace Solution PVT. LTD.
 *  
 */

import { PaginationModel, LinksModel } from '../../../../../global/models/common.models';

export enum countryUrls {
    countryCount = '/api/core/Country/TotalCount',
    search = '/api/core/Country/Search',
    dropdown = '/api/core/Country',
    insert = '/api/core/Country',
    update = '/api/core/Country',
    delete = '/api/core/Country',
}

export interface CountryGridRequestModel {
    countryCode: string;
    status: string;
    pagination: PaginationModel;
}

export const countryGridRequestData: CountryGridRequestModel = {
    countryCode: '',
    status: '1',
    pagination: {
        pageNumber: 1,
        pageSize: 15
    }
};

export interface CountryGridResponseModel {
    countryCode?: string;
    countryName?: string;
    internationalCode?: string;
    nationality?: string;
    activeStatus?: string;
    links?: LinksModel;
    stateCountLink?: string;
    stateCount?: string;
    employeeCountLink?: string;
    employeeCount?: string;
}


export const countryFormEmptyData: CountryGridResponseModel = {
    countryCode: '',
    countryName: '',
    internationalCode: '',
    nationality: '',
    activeStatus: ''
};


export const filterSkeleton = {
    selected_country: [],
    active: false,
    inactive: false
};
