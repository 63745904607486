
/* 
 *  This file sub-branch.model.ts is part of HROne Inbox. The intelectual property owned by Uneecops Workplace Solution PVT. LTD.
 *  CopyrightYear: 2022  
 *  (C) 2015-2022. All Right reserved. Uneecops Workplace Solution PVT. LTD.
 *  
 */

import { PaginationModel, LinksModel } from '../../../../../global/models/common.models';

export enum subBranchUrls {
    subBranchCount = '/api/core/SubBranch/TotalCount',
    employeeCount = '/api/core/SubBranch/EmployeeCount/',

    branchDropdown = '/api/core/Branch',
    subBranchDropdown = '/api/core/SubBranch',

    search = '/api/core/SubBranch/Search',
    insert = '/api/core/SubBranch',
    update = '/api/core/SubBranch',
    delete = '/api/core/SubBranch'
}

export interface SubBranchGridRequestModel {
    subBranchCodeFilter: string;
    branchCodeFilter: string;
    subBranchStatusFilter: string;
    pagination: PaginationModel;
}

export const subBranchGridRequestData: SubBranchGridRequestModel = {
    subBranchCodeFilter: '',
    branchCodeFilter: '',
    subBranchStatusFilter: '1',
    pagination: {
        pageNumber: 1,
        pageSize: 15
    }
};

export interface SubBranchGridResponseModel {
    branchCode: string;
    branchName: string;
    branchAddress: string;
    regionCode: string;
    stateCode: string;
    countryCode: string;
    activeStatus: string;
    pin: string;
    links: LinksModel;
    // added
    employeeCount: string;
    subBranchCount: string;
}


export const filterSkeleton = {
    selected_branch: [],
    selected_subBranch: [],
    active: true,
    inactive: false
};
