/*
 *  This file log-errors.ts is part of HROne Inbox. The intelectual property owned by Uneecops Workplace Solution PVT. LTD.
 *  CopyrightYear: 2022
 *  (C) 2015-2022. All Right reserved. Uneecops Workplace Solution PVT. LTD.
 *
 */

import { Injectable } from '@angular/core';
@Injectable()
export class LogErrorsService {
    logError(error:Error) {
        console.error('log error ', error);
    }
}
