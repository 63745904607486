/*
 *  This file organization-units.constant.ts is part of HROne Inbox. The intelectual property owned by Uneecops Workplace Solution PVT. LTD.
 *  CopyrightYear: 2022
 *  (C) 2015-2022. All Right reserved. Uneecops Workplace Solution PVT. LTD.
 *
 */

export const ouIds = {
    company: 1,
    designation: 2,
    grade: 3,
    department: 4,
    employee: 5,
    enterprise: 6,
    subDepartment: 7,
    level: 8,
    region: 9,
    branch: 10,
    subBranch: 11,
    employeeStatus: 12,
    employeeType: 13,
    businessUnit: 14
};
