/*
 *  This file images.constant.ts is part of HROne Inbox. The intelectual property owned by Uneecops Workplace Solution PVT. LTD.
 *  CopyrightYear: 2022
 *  (C) 2015-2022. All Right reserved. Uneecops Workplace Solution PVT. LTD.
 *
 */

export const imageSources = {
    img1: '/assets/images/image-placeholder.jpg',
    img2: './assets/images/employee.png',
    download_icon1: 'fa-light fa-download',
    no_image: '/assets/images/no-image.png'
};
