/*
 *  This file all-hrone.constant.ts is part of HROne Inbox. The intelectual property owned by Uneecops Workplace Solution PVT. LTD.
 *  CopyrightYear: 2022
 *  (C) 2015-2022. All Right reserved. Uneecops Workplace Solution PVT. LTD.
 *
 */

import { MENU_CODES } from './menu-codes.constant';
import { imageSources } from './images.constant' ;
import { formIds } from './forms.contant' ;
import { moduleIds } from './modules.contant' ;
import { ouIds } from './organization-units.constant' ;
import { workFlowIds } from './workflow.constant' ;
import { letterTemplateIds } from './letter-template.constant';

export const HRONE_CONSTANTS = {
    MENU_CODES: MENU_CODES,
    imageSources: imageSources,
    formIds: formIds,
    moduleIds: moduleIds,
    ouIds: ouIds,
    workFlowIds: workFlowIds,
    letterTemplateIds: letterTemplateIds,
    payrollBulkCount: 50
};





