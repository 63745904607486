// @ts-nocheck
/* 
 *  This file os-dropdowns.service.ts is part of HROne Inbox. The intelectual property owned by Uneecops Workplace Solution PVT. LTD.
 *  CopyrightYear: 2022  
 *  (C) 2015-2022. All Right reserved. Uneecops Workplace Solution PVT. LTD.
 *  
 */

import { Injectable } from '@angular/core';
import { HttpService } from '../http-service';
import * as _ from 'lodash';
import { BehaviorSubject } from 'rxjs';

export enum osDdUrls {
    company = '/api/core/Company',
    businessUnit = '/api/core/BusinessUnit',
    department = '/api/core/Department',
    subDepartment = '/api/core/SubDepartment',
    designation = '/api/core/Designation',
    grade = '/api/core/Grade',
    level = '/api/core/Level',
    enterprise = '/api/core/Enterprise',
    businessUnitCompanyWise = '/api/core/BusinessUnit/BindDropDown',
    subDepDepartmentWise = '/api/core/SubDepartment/BindDropDown',

    departmentMapped = '/api/core/Department/Mapped/', // ?businessUnitCode=
    designationMapped = '/api/core/Designation/Mapped/', // ?businessUnitCode=
    gradeMapped = '/api/core/Grade/Mapped/', // ?businessUnitCode=
    levelMapped = '/api/core/Level/Mapped/' // ?businessUnitCode=
}

@Injectable({
    providedIn: 'root'
})
export class OsDropdownsService {

    companyDd: any[] = [];
    businessUnitDd: any[] = [];
    businessUnitCompanyWiseDd: any[] = [];
    departmentDd: any[] = [];
    subDepartmentDd: any[] = [];
    subDepartmentDepartmentWiseDd: any[] = [];
    designationDd: any[] = [];
    gradeDd: any[] = [];
    levelDd: any[] = [];
    enterpriseDetail: any;

    companyActiveDd: any[] = [];
    businessUnitActiveDd: any[] = [];
    businessUnitCompanyWiseActiveDd: any[] = [];
    departmentActiveDd: any[] = [];
    subDepartmentActiveDd: any[] = [];
    subDepartmentDepartmentWiseActiveDd: any[] = [];
    designationActiveDd: any[] = [];
    gradeActiveDd: any[] = [];
    levelActiveDd: any[] = [];

    departmentMappedActiveDd: any[] = [];
    designationMappedActiveDd: any[] = [];
    gradeMappedActiveDd: any[] = [];
    levelMappedActiveDd: any[] = [];

    constructor(
        private _httpService: HttpService,
    ) { }

    resetCompanyDropDown() {
        this.companyDd = [];
        this.getCompanyDropDown();
    }

    getCompanyDropDown() {
        const bs = new BehaviorSubject([])

        if (this.companyDd.length) {
            bs.next(this.companyActiveDd)
            return bs.asObservable();
        }

        this._httpService.Get(osDdUrls.company).subscribe((response) => {

            const list = _.sortBy(response, [e => e.companyName.toLowerCase()]);
            this.companyDd = list.map(mapper => ({ value: mapper.companyName, key: mapper.companyCode }));

            const filteredList = list.filter(data => data.activeStatus === '1');
            this.companyActiveDd = filteredList.map(mapper => ({ value: mapper.companyName, key: mapper.companyCode }));
            bs.next(this.companyActiveDd);
        });
        return bs.asObservable();
    }

    resetBusinessUnitDropDown() {
        this.businessUnitDd = [];
        this.getBusinessUnitDropDown();
    }

    getBusinessUnitDropDown() {

        if (this.businessUnitDd.length) {
            return;
        }

        this._httpService.Get(osDdUrls.businessUnit).subscribe((response) => {

            const list = _.sortBy(response, [e => e.businessUnitName.toLowerCase()]);
            this.businessUnitDd = list.map(mapper => ({ value: mapper.businessUnitName, key: mapper.businessUnitCode }));

            const filteredList = list.filter(data => data.activeStatus === '1');
            this.businessUnitActiveDd = filteredList.map(mapper => ({ value: mapper.businessUnitName, key: mapper.businessUnitCode }));
        });
    }

    getBusinessUnitCompanyWiseDropDown(companyCode) {
        this.businessUnitCompanyWiseDd = [];
        this.businessUnitCompanyWiseActiveDd = [];

        const dataModel = {
            businessUnitCode: '0',
            companyCode: companyCode,
            status: '0,1',
            pagination: {
                pageNumber: 0,
                pageSize: 0
            }
        };

        this._httpService.Post(osDdUrls.businessUnitCompanyWise, dataModel).subscribe((response) => {

            const list = _.sortBy(response, [e => e.businessUnitName.toLowerCase()]);
            this.businessUnitCompanyWiseDd = list.map(mapper => ({ value: mapper.businessUnitName, key: mapper.businessUnitCode }));

            const filteredList = list.filter(data => data.activeStatus === '1');
            this.businessUnitCompanyWiseActiveDd =
                filteredList.map(mapper => ({ value: mapper.businessUnitName, key: mapper.businessUnitCode }));
        });
    }

    resetDepartmentDropDown() {
        this.departmentDd = [];
        this.getDepartmentDropDown();
    }

    getDepartmentDropDown() {

        if (this.departmentDd.length) {
            return;
        }

        this._httpService.Get(osDdUrls.department).subscribe((response) => {

            const list = _.sortBy(response, [e => e.departmentName.toLowerCase()]);
            this.departmentDd = list.map(mapper => ({ value: mapper.departmentName, key: mapper.departmentCode }));

            const filteredList = list.filter(data => data.activeStatus === '1');
            this.departmentActiveDd = filteredList.map(mapper => ({ value: mapper.departmentName, key: mapper.departmentCode }));
        });
    }

    getEnterpriseDetail() {
        this._httpService.Get(osDdUrls.enterprise).subscribe((response) => {
            this.enterpriseDetail = response[0];
        });
    }

    resetSubDepartmentDropDown() {
        this.subDepartmentDd = [];
        this.getSubDepartmentDropDown();
    }

    getSubDepartmentDropDown() {

        if (this.subDepartmentDd.length) {
            return;
        }

        this._httpService.Get(osDdUrls.subDepartment).subscribe((response) => {

            const list = _.sortBy(response, [e => e.subDepartmentName.toLowerCase()]);
            this.subDepartmentDd = list.map(mapper => ({ value: mapper.subDepartmentName, key: mapper.subDepartmentCode }));

            const filteredList = list.filter(data => data.activeStatus === '1');
            this.subDepartmentActiveDd = filteredList.map(mapper => ({ value: mapper.subDepartmentName, key: mapper.subDepartmentCode }));
        });
    }

    resetDesignationDropDown() {
        this.designationDd = [];
        this.getDesignationDropDown();
    }

    getDesignationDropDown() {

        if (this.designationDd.length) {
            return;
        }

        this._httpService.Get(osDdUrls.designation).subscribe((response) => {

            const list = _.sortBy(response, [e => e.designationName.toLowerCase()]);
            this.designationDd = list.map(mapper => ({ value: mapper.designationName, key: mapper.designationCode }));

            const filteredList = list.filter(data => data.activeStatus === '1');
            this.designationActiveDd = filteredList.map(mapper => ({ value: mapper.designationName, key: mapper.designationCode }));
        });
    }

    resetGradeDropDown() {
        this.gradeDd = [];
        this.getGradeDropDown();
    }

    getGradeDropDown() {

        if (this.gradeDd.length) {
            return;
        }

        this._httpService.Get(osDdUrls.grade).subscribe((response) => {

            const list = _.sortBy(response, [e => e.gradeName.toLowerCase()]);
            this.gradeDd = list.map(mapper => ({ value: mapper.gradeName, key: mapper.gradeCode }));

            const filteredList = list.filter(data => data.activeStatus === '1');
            this.gradeActiveDd = filteredList.map(mapper => ({ value: mapper.gradeName, key: mapper.gradeCode }));
        });
    }

    resetLevelDropDown() {
        this.levelDd = [];
        this.getLevelDropDown();
    }

    getLevelDropDown() {

        if (this.levelDd.length) {
            return;
        }

        this._httpService.Get(osDdUrls.level).subscribe((response) => {

            const list = _.sortBy(response, [e => e.levelName.toLowerCase()]);
            this.levelDd = list.map(mapper => ({ value: mapper.levelName, key: mapper.levelCode }));

            const filteredList = list.filter(data => data.activeStatus === '1');
            this.levelActiveDd = filteredList.map(mapper => ({ value: mapper.levelName, key: mapper.levelCode }));
        });
    }


    getSubDepartmentDropDownDepartmentWise(departmentCode) {
        this.subDepartmentDepartmentWiseDd = [];
        const url = osDdUrls.subDepDepartmentWise;
        const request = {
            subDepartmentCode: '',
            departmentCode: String(departmentCode),
            status: '0,1',
            pagination:
            {
                pageNumber: 0,
                pageSize: 0
            }
        };

        this._httpService.Post(url, request).subscribe((response) => {

            const list = _.sortBy(response, [e => e.subDepartmentName.toLowerCase()]);
            this.subDepartmentDepartmentWiseDd = list.map(mapper => ({ value: mapper.subDepartmentName, key: mapper.subDepartmentCode }));

            const filteredList = list.filter(data => data.activeStatus === '1');
            this.subDepartmentDepartmentWiseActiveDd = filteredList.map(mapper =>
                ({ value: mapper.subDepartmentName, key: mapper.subDepartmentCode }));
        });
    }

    // mapped dropdowns
    getDepartmentMappedDropDown(buCode) {
        this.departmentMappedActiveDd = [];
        this._httpService.Get(osDdUrls.departmentMapped + buCode).subscribe((response) => {

            const list = _.sortBy(response, [e => e.departmentName.toLowerCase()]);

            const filteredList = list.filter(data => data.activeStatus === '1');
            this.departmentMappedActiveDd = filteredList.map(mapper => ({ value: mapper.departmentName, key: mapper.departmentCode }));
        });
    }

    getDesignationMappedDropDown(buCode) {
        this.designationMappedActiveDd = [];
        this._httpService.Get(osDdUrls.designationMapped + buCode).subscribe((response) => {

            const list = _.sortBy(response, [e => e.designationName.toLowerCase()]);

            const filteredList = list.filter(data => data.activeStatus === '1');
            this.designationMappedActiveDd = filteredList.map(mapper => ({ value: mapper.designationName, key: mapper.designationCode ,gradeId : mapper.gradeId}));
        });
    }

    getGradeMappedDropDown(buCode) {
        this.gradeMappedActiveDd = [];
        this._httpService.Get(osDdUrls.gradeMapped + buCode).subscribe((response) => {

            const list = _.sortBy(response, [e => e.gradeName.toLowerCase()]);

            const filteredList = list.filter(data => data.activeStatus === '1');
            this.gradeMappedActiveDd = filteredList.map(mapper => ({ value: mapper.gradeName, key: mapper.gradeCode }));
        });
    }

    getLevelMappedDropDown(buCode) {
        this.levelMappedActiveDd = [];
        this._httpService.Get(osDdUrls.levelMapped + buCode).subscribe((response) => {

            const list = _.sortBy(response, [e => e.levelName.toLowerCase()]);

            const filteredList = list.filter(data => data.activeStatus === '1');
            this.levelMappedActiveDd = filteredList.map(mapper => ({ value: mapper.levelName, key: mapper.levelCode }));
        });
    }
    // end mapped dropdowns

    reset() {
        this.companyDd = [];
        this.businessUnitDd = [];
        this.businessUnitCompanyWiseDd = [];
        this.departmentDd = [];
        this.subDepartmentDd = [];
        this.subDepartmentDepartmentWiseDd = [];
        this.designationDd = [];
        this.gradeDd = [];
        this.levelDd = [];
        this.enterpriseDetail = null;
        this.companyActiveDd = [];
        this.businessUnitActiveDd = [];
        this.businessUnitCompanyWiseActiveDd = [];
        this.departmentActiveDd = [];
        this.subDepartmentActiveDd = [];
        this.subDepartmentDepartmentWiseActiveDd = [];
        this.designationActiveDd = [];
        this.gradeActiveDd = [];
        this.levelActiveDd = [];
        this.departmentMappedActiveDd = [];
        this.designationMappedActiveDd = [];
        this.gradeMappedActiveDd = [];
        this.levelMappedActiveDd = [];
    }

    allDropDown() {
        this.getCompanyDropDown();
        this.getBusinessUnitDropDown();
        this.getDepartmentDropDown();
        this.getSubDepartmentDropDown();
        this.getDesignationDropDown();
        this.getGradeDropDown();
        this.getLevelDropDown();
    }






}

const empSearchSkeleton = {
    enterpriseCode: '',
    companyCode: '',
    businessUnitCode: '',
    departmentCode: '',
    subDepartmentCode: '',
    gradeCode: '',
    designationCode: '',
    levelCode: '',
    branchCode: '',
    subBranchCode: '',
    regionCode: '',
    employeeCode: '',
    employeeId: 0,
    employeeName: '',
    employeeStatus: '',
    employeeType: ''
};

