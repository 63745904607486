/*
 *  This file modules.contant.ts is part of HROne Inbox. The intelectual property owned by Uneecops Workplace Solution PVT. LTD.
 *  CopyrightYear: 2022
 *  (C) 2015-2022. All Right reserved. Uneecops Workplace Solution PVT. LTD.
 *
 */

export const moduleIds = {
    workForce: 1,
    timeOffice: 2,
    payroll: 3,
    performance: 4,
    expense: 5,
    help: 6,
    asset: 7,
    training: 8,
    recruitment: 9,
    reports: 10,
    timeSheet: 11,
    travel: 12,
    integration: 13,
    incentive: 14,
    aep: 15,
    adHoc: 16,
    budget: 17,
    idCard: 18,
    core: 19,
    engage: 20,
    chat: 21
};
