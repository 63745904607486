
/* 
 *  This file state.model.ts is part of HROne Inbox. The intelectual property owned by Uneecops Workplace Solution PVT. LTD.
 *  CopyrightYear: 2022  
 *  (C) 2015-2022. All Right reserved. Uneecops Workplace Solution PVT. LTD.
 *  
 */

import { PaginationModel, LinksModel } from '../../../../../global/models/common.models';

export enum stateUrls {
    stateCount = '/api/core/State/TotalCount',
    cityCount = '/api/core/State/CityCount/',
    employeeCount = '/api/core/State/EmployeeCount/',
    search = '/api/core/State/Search',
    stateDropdown = '/api/core/State/BindDropDown',
    countryDropdown = '/api/core/Country',
    insert = '/api/core/State',
    update = '/api/core/State',
    delete = '/api/core/State',
}

export interface StateGridRequestModel {
    stateCode: string;
    countryCode: string;
    status: string;
    pagination: PaginationModel;
}

export const stateGridRequestData: StateGridRequestModel = {
    stateCode: '',
    countryCode: '',
    status: '1',
    pagination: {
        pageNumber: 1,
        pageSize: 15
    }
};

export interface StateGridResponseModel {
    stateCode: string ;
    stateName: string ;
    countryCode: string ;
    countryName: string ;
    activeStatus: string ;
    links: LinksModel;
    // added
    cityCount: string;
    employeeCount: string;
}


export const filterSkeleton = {
    selected_country: [],
    selected_state: [],
    active: true,
    inactive: false
};
