// @ts-nocheck
/*
 *  This file server-error-handler.service.ts is part of HROne Inbox. The intelectual property owned by Uneecops Workplace Solution PVT. LTD.
 *  CopyrightYear: 2022
 *  (C) 2015-2022. All Right reserved. Uneecops Workplace Solution PVT. LTD.
 *
 */

import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpHeaders, HttpClient } from '@angular/common/http';
import { throwError, forkJoin } from 'rxjs';
import { Router } from '@angular/router';
import { LogErrorsService } from './log-errors';
import { SnackbarAlertService } from '../snackbar-alert.service';
import { ConfigurationService } from '../configuration.service';
import { first, tap } from 'rxjs/operators';
import { RequestMenuModel } from '../../models/request-menu.model';
import { menuNavigationModel } from '../../constants/routing-constant';

@Injectable()
export class ServerErrorHandler {
  showAnaltyicsDashboard = false;

    constructor(
        private _router: Router,
        private _logError: LogErrorsService,
        private _snackBar: SnackbarAlertService,
        public _config: ConfigurationService,
        private _httpClient: HttpClient,
    ) {

    }

    handleServerError(err: HttpErrorResponse, isUnauthorize?) {
        const isLogin = localStorage.getItem('authlogin');
        if (navigator.onLine) {
            this._logError.logError(err);
            if (isLogin || isUnauthorize) {
                if (err instanceof HttpErrorResponse) { // Changed by shaily after discussed with raju sir
                     if (err.status === 0) {
                        this._snackBar.Alert('You are in low internet bandwidth. Please check and try again', '2');
                        // changed message by shaily after discussed with raju sir
                    } else if (err.status === 426) {
                        if (typeof err.error === 'string') {
                            this._snackBar.Alert(err.error, '2');
                        }
                        this.onRefreshLogon();
                    } else if (err.status !== 306) {
                        console.log('Server', err);
                        if (err.error && err.error.message && err.error.validationType && err.error.validationType == '3') {
                            this._snackBar.Alert(err.error.message, '3');
                        } else if (err.error && err.error.message) {
                            this._snackBar.Alert(err.error.message, '2');
                        } else if (err.error && err.error.error_description) {
                            this._snackBar.Alert(err.error.error_description, '2');
                        } else if (err.error && err.error.error) {
                            if (err.error.error != 'invalid_grant') {
                                this._snackBar.Alert(err.error.error, '2');
                            }
                        } else if (typeof err.error === 'string') {
                            if (!(this.showAnaltyicsDashboard)) {
                                this._snackBar.Alert(err.error, '2');
                            }
                        } else {
                            this._snackBar.Alert('An error occurred: ' + err.name, '2');
                        }
                    }

                } else {
                    if((err as any).status === 5999){
                        console.log({err})
                    }
                    else{
                    this._snackBar.Alert(`Unknown error`, '2');
                    }
                }
            }
        } else {
            this._snackBar.Alert(`Please check your internet connection`, '2');
        }

        return throwError(err);
    }

    onRefreshLogon() {
        if (localStorage.getItem('authlogin')) {
            const url = this._config.base_url + '/api/LogOnUser/refresh/LogOnUser/PageAccessRight/W';
            const headers = { headers: this.getHeaders() };
            this._httpClient.get(url, headers)
                .subscribe(res => {
                    if (res) {
                        this.getMenusAndRights().then(result => {
                            location.reload();
                        });
                    }
                });
        }
    }

    getMenusAndRights() {

        return new Promise((resolve, reject) => {

            const MenuModelObject = {
                menuType: 'P',
                parentKey: 9999,
                menuId: 0,
                includeMenu: true
            };

            const urlMAR = this._config.base_url + '/api/core/MenuAccess';
            const urlMenus = this._config.base_url + '/api/core/MenuAccess';
            const headers = { headers: this.getHeaders() };

            const menuAction = this._httpClient.get(urlMAR, headers);
            const menuList = this._httpClient.post(urlMenus, MenuModelObject, headers);

            forkJoin([menuAction, menuList])
                .pipe(
                    first(),
                    tap((data: any) => {
                        if (data[0].menuPermissionRights && data[0].menuPermissionRights.length) {
                            data[0].menuPermissionRights = data[0].menuPermissionRights.map(menu => {
                                menu.navigationCode = (menu.navigationCode || '').toLowerCase();
                                return menu;
                            });
                        }
                        localStorage.setItem('rawMenusWithActions', JSON.stringify(data[0].menuPermissionRights));

                        // Modify raw menus
                        if (data[1].length) {
                            data[1] = data[1].map(menu => {
                                menu.navigationCode = (menu.navigationCode || '').toLowerCase();
                                return menu;
                            });
                        }
                        const rawMenus = data[1];
                        try { rawMenus.find(e => e.menuId === 64).sequenceNumber = 6; } catch (e) { }
                        const rawMenusNew = RequestMenuModel.concat(rawMenus);
                        menuNavigationModel.forEach(item => {
                            try {
                                rawMenusNew.find(e => e.navigationCode === item.code).navigationPath = item.path;
                            } catch (e) { }
                        });
                        localStorage.setItem('rawMenus', JSON.stringify(rawMenusNew));
                        // End modify raw menus

                        resolve(true);

                    })
                ).toPromise().then(data => { }).catch(err => {
                    reject(false);
                });

        });

    }

    getHeaders() {
        const authlogin = JSON.parse(localStorage.getItem('authlogin'));
        const accessToken = authlogin ? authlogin.access_token : '';
        const dc = localStorage.getItem('domainCode') ? localStorage.getItem('domainCode') : this._config.domainCode;
        return new HttpHeaders({
            'Content-Type': 'application/json',
            'domainCode': dc,
            'Authorization': 'Bearer ' + accessToken,
            'AccessMode': 'W'
        });

    }

}
