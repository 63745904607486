/*
 *  This file forms.contant.ts is part of HROne Inbox. The intelectual property owned by Uneecops Workplace Solution PVT. LTD.
 *  CopyrightYear: 2022
 *  (C) 2015-2022. All Right reserved. Uneecops Workplace Solution PVT. LTD.
 *
 */

export const formIds = {
    candidate: 1,
    other: 2,
    snapshot: 3,
    personal: 4,
    family: 5,
    professional: 6,
    healthInsurance: 7,
    previousEmployerDetails: 9,
    document: 11,
    bank: 8,
    skills : 10,
};


