// @ts-nocheck
/* 
 *  This file user-rights.service.ts is part of HROne Inbox. The intelectual property owned by Uneecops Workplace Solution PVT. LTD.
 *  CopyrightYear: 2022  
 *  (C) 2015-2022. All Right reserved. Uneecops Workplace Solution PVT. LTD.
 *  
 */

import { Injectable } from '@angular/core';
import { MENU_CODES } from '../constants/menu-codes.constant';

export interface UserRightsModel {
    view: boolean;
    add: boolean;
    update: boolean;
    delete: boolean;
}

@Injectable()
export class UserRightsService {

    rawMenusWithActions: any = [];

    rights: UserRightsModel = {
        view: false,
        add: false,
        update: false,
        delete: false,
    };

    resetRights() {
        this.rights = {
            view: false,
            add: false,
            update: false,
            delete: false,
        };
    }

    getRights(navCode, from = '') {
        this.resetRights();
        const actions = this.rawMenusWithActions.filter(item => item.navigationCode === navCode);
        console.log('actionssss----', actions);

        switch (navCode) {
            // *************************** PROCESSS **************************
            // --------------- PROCESS-WORKFORCE ----------------
            case MENU_CODES.PROCESS.PROFILE:
                if (from == 'personal') {
                    this.rights.view = actions.findIndex(data => data.accessRightId === 2301) >= 0;
                    this.rights.add = true;
                    this.rights.update = actions.findIndex(data => data.accessRightId === 2302) >= 0;
                } else if (from == 'family') {
                    this.rights.view = actions.findIndex(data => data.accessRightId === 2303) >= 0;
                    this.rights.add = true;
                    this.rights.update = actions.findIndex(data => data.accessRightId === 2304) >= 0;
                } else if (from == 'professional') {
                    this.rights.view = actions.findIndex(data => data.accessRightId === 2305) >= 0;
                    this.rights.add = true;
                    this.rights.update = actions.findIndex(data => data.accessRightId === 2306) >= 0;
                } else if (from == 'health') {
                    this.rights.view = actions.findIndex(data => data.accessRightId === 2307) >= 0;
                    this.rights.add = true;
                    this.rights.update = actions.findIndex(data => data.accessRightId === 2308) >= 0;
                } else if (from == 'other') {
                    this.rights.view = actions.findIndex(data => data.accessRightId === 2309) >= 0;
                    this.rights.add = true;
                    this.rights.update = actions.findIndex(data => data.accessRightId === 2310) >= 0;
                } else if (from == 'employer') {
                    this.rights.view = actions.findIndex(data => data.accessRightId === 2313) >= 0;
                    this.rights.add = true;
                    this.rights.update = actions.findIndex(data => data.accessRightId === 2314) >= 0;
                } else if (from == 'documents') {
                    this.rights.view = actions.findIndex(data => data.accessRightId === 2317) >= 0;
                    this.rights.add = true;
                    this.rights.update = actions.findIndex(data => data.accessRightId === 2318) >= 0;
                } else if (from == 'bank') {
                    this.rights.view = actions.findIndex(data => data.accessRightId === 2311) >= 0;
                    this.rights.add = true;
                    this.rights.update = actions.findIndex(data => data.accessRightId === 2312) >= 0;
                } else if (from == 'contract') {
                    this.rights.view = actions.findIndex(data => data.accessRightId === 2322) >= 0;
                    this.rights.add = true;
                    this.rights.update = actions.findIndex(data => data.accessRightId === 2323) >= 0;
                } else if (from == 'skills') {
                    this.rights.view = actions.findIndex(data => data.accessRightId === 2315) >= 0;
                    this.rights.add = true;
                    this.rights.update = actions.findIndex(data => data.accessRightId === 2316) >= 0;
                } else {
                    this.rights.view = actions.findIndex(data => data.accessRightId === 75) >= 0;
                    this.rights.add = actions.findIndex(data => data.accessRightId === 77) >= 0;
                    this.rights.update = actions.findIndex(data => data.accessRightId === 76) >= 0;
                }
                break;

            // --------------- END PROCESS-WORKFORCE ----------------
            // *************************** END PROCESSS **************************

            // *************************** CORE **************************
            // --------------- CORE-WORKFORCE ----------------
            case MENU_CODES.CORE.BRANCH:
                this.rights.view = actions.findIndex(data => data.accessRightId === 37) >= 0;
                this.rights.add = actions.findIndex(data => data.accessRightId === 38) >= 0;
                this.rights.update = actions.findIndex(data => data.accessRightId === 39) >= 0;
                this.rights.delete = actions.findIndex(data => data.accessRightId === 40) >= 0;
                break;

            // --------------- END CORE-WORKFORCE ----------------
            // *************************** END CORE **************************

            default:
                this.rights = {
                    view: false,
                    add: false,
                    update: false,
                    delete: false,
                };
        }

        return this.rights;

    }

}
