/*
 *  This file load-script.service.ts is part of HROne Inbox. The intelectual property owned by Uneecops Workplace Solution PVT. LTD.
 *  CopyrightYear: 2022
 *  (C) 2015-2022. All Right reserved. Uneecops Workplace Solution PVT. LTD.
 *
 */

import { Injectable } from "@angular/core";

interface Scripts {
    name: string;
    src: string;
    integrity?: string;
}

export const scriptStore: Scripts[] = [
    {name: 'highchart', src: 'https://code.highcharts.com/highcharts.js'},
    {name: 'highchartMore', src: "https://code.highcharts.com/highcharts-more.js"},
    {name: 'highchartHeatmap', src: 'https://code.highcharts.com/modules/heatmap.js'},
    {name: 'highchartExport', src: 'https://code.highcharts.com/modules/exporting.js'},
    {name: 'export', src: "https://code.highcharts.com/modules/export-data.js"},
    {name: 'leaflet', src: 'https://unpkg.com/leaflet@1.5.1/dist/leaflet.js',
        integrity: 'sha512-GffPMF3RvMeYyc1LWMHtK8EbPv0iNZ8/oTtHPx9/cc2ILxQ+u905qIwdpULaqDkyBKgOaB57QTMg7ztg8Jm2Og=='},
    {name: 'googlePlatform', src: 'https://apis.google.com/js/platform.js'},
    {name: 'msal', src: 'https://alcdn.msauth.net/lib/1.3.0/js/msal.js'},
    {name: 'gaugeChart', src: "https://code.highcharts.com/modules/solid-gauge.js"},
    {name: 'accessibility', src: "https://code.highcharts.com/modules/accessibility.js"},
    {name: "hubspot", src:"//js.hs-scripts.com/7277696.js"}
];

declare let document: any;

@Injectable()
export class LoadScriptService {

    private scripts: any = {};

    constructor() {
        scriptStore.forEach((script: Scripts) => {
            this.scripts[script.name] = {
                loaded: false,
                src: script.src
            };
        });
    }

    load(...scripts: string[]) {
        const promises: any[] = [];
        scripts.forEach((script) => promises.push(this.loadScript(script)));
        return Promise.all(promises);
    }

    loadScript(name: string) {
        return new Promise((resolve) => {
            // resolve if already loaded
            if (this.scripts[name].loaded) {
                resolve({ script: name, loaded: true, status: 'Already Loaded' });
            } else {
                // load script
                const script = document.createElement('script');
                script.type = 'text/javascript';
                script.src = this.scripts[name].src;
                if (this.scripts[name].integrity) {
                    script.integrity = this.scripts[name].integrity;
                }
                if (script.readyState) {  // IE
                    script.onreadystatechange = () => {
                        if (script.readyState === "loaded" || script.readyState === "complete") {
                            script.onreadystatechange = null;
                            this.scripts[name].loaded = true;
                            resolve({ script: name, loaded: true, status: 'Loaded' });
                        }
                    };
                } else {  // Others
                    script.onload = () => {
                        this.scripts[name].loaded = true;
                        resolve({ script: name, loaded: true, status: 'Loaded' });
                    };
                }
                script.onerror = () => resolve({ script: name, loaded: false, status: 'Loaded' });
                document.getElementsByTagName('head')[0].appendChild(script);
            }
        });
    }

}
