// @ts-nocheck
/*
 *  This file progress-button.service.ts is part of HROne Inbox. The intelectual property owned by Uneecops Workplace Solution PVT. LTD.
 *  CopyrightYear: 2022
 *  (C) 2015-2022. All Right reserved. Uneecops Workplace Solution PVT. LTD.
 *
 */

import { Injectable } from '@angular/core';


@Injectable()
export class ProgressButtonService {
    buttons: ProgressModel[] = [];
    inactivateTooltip = 'Once inactivated cannot be reactivated';

    t1; t2; t3; t4;
    bt1; bt2; bt3; bt4;

    clicked(index: number) {
        this.buttons[index].buttonName = 'Processing';
        this.buttons[index].progress = 0; // starts spinner
        this.t1 = setTimeout(() => {
            this.buttons[index].progress = 0.2; // sets progress bar to 20%
            this.t2 = setTimeout(() => {
                this.buttons[index].progress = 0.4; // sets progress bar to 40%
                this.t3 = setTimeout(() => {
                    this.buttons[index].progress = 0.6; // sets progress bar to 60%
                    this.t4 = setTimeout(() => {
                        this.buttons[index].progress = 0.8; // sets progress bar to 80%
                    }, 300);
                }, 300);
            }, 300);
        }, 300);
    }

    finished(index: number, buttonName: string, validationType: string) {
        clearTimeout(this.t1);
        clearTimeout(this.t2);
        clearTimeout(this.t3);
        clearTimeout(this.t4);
        setTimeout(() => {
            this.buttons[index].progress = 1;
            setTimeout(() => {
                this.buttons[index].buttonName = this.showresult(validationType);
                this.buttons[index].progress = false;
                setTimeout(() => {
                    this.buttons[index].buttonName = buttonName;
                }, 1000);
            }, 200);
        }, 500);
    }

    showresult(validationType) {
        switch (validationType) {
            case '0':
                return '✔';
            case '2':
                return 'X';
            case '3':
                return '!';
        }
    }

    clickedNew(button: ProgressModel) {
        this.clearTimeout();
        button.buttonName = 'Processing';
        button.progress = 0; // starts spinner
        this.bt1 = setTimeout(() => {
            button.progress = 0.2; // sets progress bar to 20%
            this.bt2 = setTimeout(() => {
                button.progress = 0.4; // sets progress bar to 40%
                this.bt3 = setTimeout(() => {
                    button.progress = 0.6; // sets progress bar to 60%
                    this.bt4 = setTimeout(() => {
                        button.progress = 0.8; // sets progress bar to 80%
                    }, 300);
                }, 300);
            }, 300);
        }, 300);
    }

    finishedNew(button, buttonName, validationType?: string) {
        this.clearTimeout();
        setTimeout(() => {
            button.progress = 1;
            setTimeout(() => {
                button.buttonName = buttonName;
                button.progress = false;
            }, 200);
        }, 500);
    }

    clearTimeout() {
        clearTimeout(this.bt1);
        clearTimeout(this.bt2);
        clearTimeout(this.bt3);
        clearTimeout(this.bt4);
    }

}

export interface ProgressModel {
    progress: boolean | number;
    buttonName: string;
}
