
/*
 *  This file app-routing.module.ts is part of HROne Inbox. The intelectual property owned by Uneecops Workplace Solution PVT. LTD.
 *  CopyrightYear: 2022
 *  (C) 2015-2022. All Right reserved. Uneecops Workplace Solution PVT. LTD.
 *
 */

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from './global/guard/auth-guard-service';
import { RedirectToHomeGuardService } from './global/guard/redirect-to-home-guard.service';
import { LogonUserResolverService } from './global/services/logon-user-resolver.service';


const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  {
    path: 'app', loadChildren: () => import('./main/main.module').then(m => m.MainModule), canLoad: [AuthGuardService],
    resolve: {
      user: LogonUserResolverService
    }
  },
  {
    path: 'login', loadChildren: () => import('./authentication/login/login.module').then(m => m.LoginModule),
    canLoad: [RedirectToHomeGuardService]
  },
  { path: 'onboarding-config', loadChildren: () => import('./modules/onboarding-config/onboarding-config.module').then(m => m.OnboardingConfigModule) }, 
  {
    path: 'hroneAuth', loadChildren: () => import('./authentication/saml-auth/saml-auth.module').then(m => m.SamlAuthModule),
    canLoad: [RedirectToHomeGuardService]
  },
  {
    path: 'client-feedback',
    loadChildren: () => import('./modules/performance/inbox/policy360-approval/policy360-client-feedback-approval/policy360-client-feedback-approval.module').then(m => m.Policy360ClientFeedbackApprovalModule)
  },
  {
    path: 'candidate-profile-completion',
    loadChildren: () => import('./modules/recruitment/candidate/add-candidate/add-candidate.module').then(m => m.AddCandidateModule)
  },
  {
    path: 'candidate-document-completion',
    loadChildren: () => import('./modules/recruitment/candidate/candidate-document/candidate-document.module').then(m => m.CandidateDocumentModule)
  },
  {
    path: 'candidate-offer',
    loadChildren: () => import('./modules/recruitment/candidate/candidate-offer-letter/candidate-offer-letter.module').then(m => m.CandidateOfferLetterModule)
  },
  {
    path: 'career-portal',
    loadChildren: () => import('./modules/recruitment/core/recruitment-setting/carrer-portal/preview/preview.module').then(m => m.PreviewModule)
  },
  {
    path: 'apply-job',
    loadChildren: () => import('./modules/recruitment/core/recruitment-setting/carrer-portal/apply-job/apply-job.module').then(m => m.ApplyJobModule)
  },

  {
    path: 'support',
    loadChildren: () => import('./modules/support/support.module').then(m => m.SupportModule)
  },
  {
    path: 'snapshot-details',
    loadChildren: () => import('./modules/processes/workforce/manage-employee/create-employee-components/upcoming-emp/external-component/snapshot/snapshot.module').then(m => m.SnapshotModule)
  },
  {
    path: 'personal-details',
    loadChildren: () => import('./modules/processes/workforce/manage-employee/create-employee-components/upcoming-emp/external-component/personal/personal.module').then(m => m.PersonalModule)

  },
  {
    path: 'professional-details',
    loadChildren: () => import('./modules/processes/workforce/manage-employee/create-employee-components/upcoming-emp/external-component/professional/professional.module').then(m => m.ProfessionalModule)
  },
  {
    path: 'family-details',
    loadChildren: () => import('./modules/processes/workforce/manage-employee/create-employee-components/upcoming-emp/external-component/family/family.module').then(m => m.FamilyModule)
  },
  {
    path: 'health-insurance-details',
    loadChildren: () => import('./modules/processes/workforce/manage-employee/create-employee-components/upcoming-emp/external-component/health-insurance/health-insurance.module').then(m => m.HealthInsuranceModule)
  },
  {
    path: 'other-details',
    loadChildren: () => import('./modules/processes/workforce/manage-employee/create-employee-components/upcoming-emp/external-component/other-details/other-details.module').then(m => m.OtherDetailsModule)
  },
  {
    path: 'bank-details',
    loadChildren: () => import('./modules/processes/workforce/manage-employee/create-employee-components/upcoming-emp/external-component/bank/bank.module').then(m => m.BankModule)
  },
  {
    path: 'previous-details',
    loadChildren: () => import('./modules/processes/workforce/manage-employee/create-employee-components/upcoming-emp/external-component/previous-details/previous-details.module').then(m => m.PreviousDetailsModule)
  },
  {
    path: 'documents-details',
    loadChildren: () => import('./modules/processes/workforce/manage-employee/create-employee-components/upcoming-emp/external-component/documnets/documnets.module').then(m => m.DocumnetsModule)
  },
  { path: 'google-meet', loadComponent: () => import('./global/components/google-meet-redirect/google-meet-redirect.component').then(m => m.GoogleMeetRedirectComponent) },
  { path: 'esignature', loadComponent: () => import('./global/components/esignature/esignature.component').then(m => m.EsignatureComponent) },
  { path: 'signature-success', loadComponent: () => import('./global/components/esignature-redirect/esignature-redirect.component').then(m => m.EsignatureRedirectComponent) },
  { path: 'signature-error', loadComponent: () => import('./global/components/esignature-redirect/esignature-redirect.component').then(m => m.EsignatureRedirectComponent) },
  { path: 'signature-cancel', loadComponent: () => import('./global/components/esignature-redirect/esignature-redirect.component').then(m => m.EsignatureRedirectComponent) },
  {
    path: 'verify-kyc', loadChildren: () => import('./modules/recruitment/verify-kyc/verify-kyc.module').then(m => m.VerifyKycModule)
  },
  {
    path: 'digiAccept',
    loadChildren: () => import('./modules/settlement/digital-acceptance/digital-acceptance.module').then(m => m.DigitalAcceptanceModule)
  },
  {
    path: 'new-profile',
    loadChildren: () => import('./modules/new-profile-menus/new-profile-menus-routes').then(m => m.NEW_PROFILE_MENUS)
  },
  { path: 'teams-meet', loadComponent: () => import('./global/components/google-meet-redirect/google-meet-redirect.component').then(m => m.GoogleMeetRedirectComponent) },
  { path: 'profile-completion', loadComponent: () => import('./modules/processes/workforce/manage-employee/create-employee-components/upcoming-emp/external-component/profile-completion/profile-completion.component').then(m => m.ProfileCompletionComponent) },
  { path: 'candidate-list-new/:requestId', loadComponent: () => import('src/app/modules/recruitment/candidate/candidateList1/CandidateListNew.component').then(m => m.CandidateListNewComponent), 
    resolve: {
      user: LogonUserResolverService
    } 
  },
  {
    path: 'candidate-list/:requestId/:employeeId/:transactionId/:isArchived/:tabIndex/:from',
    loadChildren: () => import('./modules/recruitment/candidate/candidate-list/candidate-list.module').then(m => m.CandidateListModule),
    resolve: {
      user: LogonUserResolverService
    } 
  },
  { path: '**', loadChildren: () => import('./authentication/not-found/not-found.module').then(m => m.NotFoundModule) },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
