// @ts-nocheck
/* 
 *  This file auth-guard-service.ts is part of HROne Inbox. The intelectual property owned by Uneecops Workplace Solution PVT. LTD.
 *  CopyrightYear: 2022  
 *  (C) 2015-2022. All Right reserved. Uneecops Workplace Solution PVT. LTD.
 *  
 */

import { Injectable } from '@angular/core';
import { Router, CanLoad } from '@angular/router';

import { AuthService } from '../services/auth-service';

@Injectable()
export class AuthGuardService implements CanLoad {

    constructor(
        public _authService: AuthService,
        public _router: Router
    ) {

    }

    canLoad(): boolean {
        if (this._authService.isAuthenticated()) {
            return true;
        } else {
            this._authService.logout();
            return false;
        }
    }
}
